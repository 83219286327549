<template>
  <div>
    <h2 class="th-title">Transaksi</h2>
    <a-card>
      <div class="d-flex justify-content-between mb-3">
        <a-input
          style="width: 300px"
          placeholder="Cari ID Transaksi"
          v-model:value="search"
          @change="onSearch"
        >
          <template #prefix>
            <span>
              <search-outlined style="color: rgba(0, 0, 0, 0.25)" />
            </span>
          </template>
        </a-input>
      </div>
      <div>
        <a-table :columns="columns" :data-source="dataTable" :loading="loadingTable" bordered>
          <template #createdAt="{ text, record }">
            <div class="d-flex flex-column">
              <a-tag class="align-self-start" v-if="record.status === 'success'" color="success">
                <template #icon>
                  <check-circle-outlined />
                </template>
                Berhasil
              </a-tag>
              <a-tag class="align-self-start" v-if="record.status === 'pending'" color="warning">
                <template #icon>
                  <clock-circle-outlined />
                </template>
                Menunggu Pembayaran
              </a-tag>
              <a-tag class="align-self-start" v-if="record.status === 'cancel'" color="error">
                <template #icon>
                  <close-circle-outlined />
                </template>
                Dibatalkan
              </a-tag>
              <span class="mt-2">{{ text }}</span>
              <span class="mt-2">{{ record.external_id }}</span>
            </div>
          </template>
          <template #totalPrice="{ text }">
            {{ priceFormat(text) }}
          </template>
          <template #expandedRowRender="{ record }">
            <a-table
              :columns="innerColumns"
              :data-source="record.transactionDetails"
              :pagination="false"
            >
              <template #coursePrice="{ text }">
                <div v-if="record.discountPercentage" class="d-flex flex-column">
                  <del class="mr-2"
                    ><small>{{ priceFormat(text) }}</small></del
                  >
                  <span>{{ priceFormat(recordPrice.discountedPrice) }}</span>
                </div>
                <span v-else>{{ priceFormat(text) }}</span>
              </template>
            </a-table>
          </template>
        </a-table>
      </div>
    </a-card>
  </div>
</template>

<script>
import moment from 'moment';
import priceFormat from '@/helpers/rupiahPrice';
import { onMounted, reactive, ref, toRaw } from 'vue';
import { useStore } from 'vuex';
import { SearchOutlined , CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined } from '@ant-design/icons-vue'

const columns = [
  {
    title: 'Tanggal Transaksi',
    dataIndex: 'createdAt',
    key: 'createdAt',
    slots: { customRender: 'createdAt' },
  },
  {
    title: 'Pembeli',
    dataIndex: 'name',
    key: 'name',
    slots: { customRender: 'name' },
  },
  {
    title: 'Jumlah Course',
    dataIndex: 'totalCourse',
    key: 'totalCourse',
    slots: { customRender: 'totalCourse' },
    align: 'center',
  },
  {
    title: 'Total Biaya',
    dataIndex: 'totalPrice',
    key: 'totalPrice',
    slots: { customRender: 'totalPrice' },
    align: 'right',
  },
  // {
  //   title: 'Aksi',
  //   key: 'action',
  //   slots: { customRender: 'action' },
  //   align: 'center',
  // },
];
const innerColumns = [
  {
    title: 'Course',
    dataIndex: 'courseName',
    key: 'courseName',
    slots: { customRender: 'courseName' },
  },
  {
    title: 'Harga',
    dataIndex: 'coursePrice',
    key: 'coursePrice',
    slots: { customRender: 'coursePrice' },
  },
];
export default {
  components: {
    SearchOutlined,
    CheckCircleOutlined,
    ClockCircleOutlined,
    CloseCircleOutlined,
    // PlusOutlined,
    // CheckOutlined,
    // CloseOutlined,
  },
  setup() {
    const store = useStore()
    let dataTable = ref([])
    let loadingTable = ref(false)
    let search = ref(null)

    onMounted(() => {
      fetchTransactions()
    })

    const onSearch = (e) => {
      console.log(e.target.value);
      fetchTransactions(e.target.value)
    }

    const addingKey = (data) => {
      return data.map(el => {
        return {
          ...el,
          key: el._id,
        }
      })
    }

    const fetchTransactions = async (search = '') => {
      loadingTable.value = true
      const data = await store.dispatch('transaction/FETCH_ALL_TRANSACTION', search)
      loadingTable.value = false
      dataTable.value = data.map((el => {
        return {
          ...el,
          key: el._id,
          name: el.customer ? el.customer.firstName + ' ' + el.customer.lastName : '-',
          createdAt: moment(el.createdAt).locale('id').format('dddd, DD MMMM YYYY HH:mm:ss'),
          totalCourse: el.transactionDetails.length,
          transactionDetails: addingKey(el.transactionDetails),
        }
      }))
    }

    return {
      search,
      columns,
      dataTable,
      loadingTable,
      innerColumns,
      priceFormat,
      onSearch,
    }
  },
}
</script>
